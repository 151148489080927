// ** Logo
import logo from "@src/assets/svg/torgLogo.svg"
import { Spinner } from "reactstrap"

const SpinnerComponent = () => {
    return (
        <div className="fallback-spinner app-loader">
            <img
                className="fallback-logo"
                src={logo}
                alt="logo"
            />
            <div className="loading">
                <Spinner
                    color="primary"
                    style={{ width: "50px", height: "50px" }}
                />
            </div>
        </div>
    )
}

export default SpinnerComponent
