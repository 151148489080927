// You can customize the template with the help of this file

//Template config options
import logo from "../assets/svg/torgLogo.svg"
const themeConfig = {
    app: {
        appName: "TORC",
        appLogoImage: logo
    },
    layout: {
        isRTL: false,
        skin: "light",
        type: "vertical",
        contentWidth: "boxed",
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            type: "floating",
            backgroundColor: "white"
        },
        footer: {
            type: "static"
        },
        customizer: false,
        scrollTop: true, // Enable scroll to top button
        toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
    }
}

export default themeConfig
