import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload
    },
    logOut(state) {
      state.user = null
      localStorage.removeItem('Email')
      localStorage.removeItem('Token')

    }
  }
})

export const { login, logOut } = userSlice.actions
export default userSlice.reducer
