/* eslint-disable*/

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const serviceChargeSlice = createSlice({
    name: "serviceCharge",
    initialState: {
        towingData: {},
        fuelData: [],
        singleFuelData: {},
        lockoutData: {},
        tireData: {},
        batteryData: {}
    },
    reducers: {
        addTowingData(state, action) {
            state.towingData = action.payload
        },
        addFuelData(state, action) {
            state.fuelData = action.payload
        },
        selectFuel(state, action) {
            state.singleFuelData = action.payload
        },
        addGallon(state, action) {
            state.singleFuelData?.choose_gallon?.push(action.payload)
        },
        removeGallon(state, action) {
            state.singleFuelData?.choose_gallon?.splice(action.payload, 1)
        },
        addLockoutData(state, action) {
            state.lockoutData = action.payload
        },
        addTireData(state, action) {
            state.tireData = action.payload
        },
        addBatteryData(state, action) {
            state.batteryData = action.payload
        }
    }
})

export const {
    addTowingData,
    addFuelData,
    addLockoutData,
    addTireData,
    addBatteryData,
    selectFuel,
    addGallon,
    removeGallon
} = serviceChargeSlice.actions

export default serviceChargeSlice.reducer
