// ** Reducers Imports
import user from "./action/user"
import layout from "./slices/layout"
import navbar from "./slices/navbar"
import serviceCharge from "./slices/serviceCharge"

const rootReducer = {
    user,
    navbar,
    layout,
    serviceCharge
}

export default rootReducer
